<template>
    <div class="dark:bg-gray-900 bg-white py-24 text-gray-100">
        <div class="lg:container mx-auto">
            <a class="py-3 px-6 bg-blue-400 sm:m-10 lg:m-0 hover:bg-teal-400 cursor-pointer"
               @click.prevent="$router.back()">Назад</a>
            <h1 class="sm:p-10 lg:p-0 text-4xl dark:text-white text-gray-700 my-10">{{ item.title }}</h1>
            <div class="dark:bg-gray-800 rounded p-10 dark:shadow-none shadow-2xl">
                <span class="block text-right text-md text-gray-700 dark:text-gray-300 pb-10">{{
                        item.created_at
                    }}</span>
                <div class="text-lg flex flex-col items-center dark:text-white text-gray-700 html-text"
                     v-html="item.description"></div>
            </div>
        </div>

    </div>
</template>

<script>

import axios from "axios";

export default {
    name: "NewsDetail",
    meta:{
        title:'Новости'
    },
    data: () => ({
        item: {}
    }),
    created() {
        this.fetch()
    },
    methods: {
        async fetch() {
            let code = this.$route.params.code
            axios.get(`https://declarant.by/rest/news/${code}`)
                .then(res => {
                    this.item = res.data.data
                })
        }

    }
}
</script>

<style scoped>

p {
    margin: 0 0 20px 0 !important;
    display: flex !important;
    justify-content: center !important;
}
.html-text >>>  a{
    color: #2196F3;
}


</style>